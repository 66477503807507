import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/components/forms/EnquiryForm/messages';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormItem from '@rentivo/gatsby-core/src/components/forms/FormItem';
import {
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Textarea,
  Grid,
  GridItem,
  Box,
  chakra
} from '@chakra-ui/react';
import { Button } from '@rentivo/gatsby-core/index';
import AvailabilityPickerPopover from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PropertyEnquiry/components/PropertyEnquiryForm/components/AvailabilityPickerPopover';

const EnquiryFormComponent = ({form, loading, showClearForm, startDate, endDate, setStartDate, setEndDate, clearForm, areGuestsRequired, isMessageRequired, defaultValues, emptyValues, className, styleProps = {}}) => {
  const { formatMessage } = useIntl();
  const { reset, register, errors } = form;

  return (

    <Box position="relative" mt={1} className={className} {...styleProps}>

      {showClearForm && (
        <>
          {!isEmpty(defaultValues) && (
            <Button
              size="sm"
              type="muted"
              leftIcon={<FontAwesomeIcon icon={faTimes}/>}
              onClick={() => {
                setStartDate(null);
                setEndDate(null);
                clearForm();
                reset(emptyValues);
              }}
              position="absolute"
              zIndex={3}
              right={-3}
              top={-3}
            >
              <FormattedMessage {...messages.clearFormLabel}/>
            </Button>
          )}
        </>
      )}

      <Grid templateColumns="repeat(4, 1fr)" gap={6}>
        <GridItem colSpan={4}>
          <AvailabilityPickerPopover
            styleProps={{mb: 0}}
            componentProps={{
              defaultValues: (defaultValues.startDate && defaultValues.endDate) ? [defaultValues.startDate, defaultValues.endDate] : null,
              startDate,
              endDate,
              setStartDate,
              setEndDate
            }}
          />
          <input type="hidden" name="startDateHidden" value={startDate} style={{display: 'none'}} ref={register({ required: true })}/>
          {(errors.startDateHidden || errors.endDateHidden) && <chakra.div color="red.500" fontSize="sm" mt={2}><FormattedMessage {...messages.inputDatesErrorRequired}/></chakra.div>}
        </GridItem>

        <GridItem colSpan={{base: 4, md: 2}}>
          <FormItem
            name="firstName"
            label={formatMessage(messages.inputFirstNameLabel)}
            rules={{
              required: true
            }}
            render={props => (
              <Input
                type="text"
                placeholder={formatMessage(messages.inputFirstNameLabel)}
                {...props}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={{base: 4, md: 2}}>
          <FormItem
            name="lastName"
            label={formatMessage(messages.inputLastNameLabel)}
            rules={{
              required: true
            }}
            render={props => (
              <Input
                type="text"
                placeholder={formatMessage(messages.inputLastNameLabel)}
                {...props}
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={{base: 4, md: 2}}>
          <FormItem
            name="email"
            label={formatMessage(messages.inputEmailLabel)}
            rules={{
              required: true,
              email: true
            }}
            render={props => (
              <Input
                type="email"
                placeholder={formatMessage(messages.inputEmailLabel)}
                {...props}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={{base: 4, md: 2}}>
          <FormItem
            name="phone"
            label={formatMessage(messages.inputPhoneLabel)}
            rules={{
              required: true
            }}
            render={props => (
              <Input
                type="text"
                placeholder={formatMessage(messages.inputPhoneLabel)}
                {...props}
              />
            )}
          />
        </GridItem>


        <GridItem colSpan={{base: 2, md: 1}}>
          <FormItem
            name="adults"
            label={formatMessage(messages.inputGuestAdultCountLabel)}
            rules={{
              required: areGuestsRequired,
              min: 1
            }}
            render={props => (
              <NumberInput min={0} max={99} placeholder={0} {...props}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            )}
          />
        </GridItem>

        <GridItem colSpan={{base: 2, md: 1}}>
          <FormItem
            name="children"
            label={formatMessage(messages.inputGuestChildrenCountLabel)}
            rules={{
              required: areGuestsRequired
            }}
            render={props => (
              <NumberInput min={0} max={99} placeholder={0} {...props}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            )}
          />
        </GridItem>

        <GridItem colSpan={{base: 2, md: 1}}>
          <FormItem
            name="infants"
            label={formatMessage(messages.inputGuestInfantCountLabel)}
            rules={{
              required: areGuestsRequired
            }}
            render={props => (
              <NumberInput min={0} max={99} placeholder={0} {...props}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            )}
          />
        </GridItem>

        <GridItem colSpan={{base: 2, md: 1}}>
          <FormItem
            name="pets"
            label={formatMessage(messages.inputPetsCountLabel)}
            rules={{
              required: areGuestsRequired
            }}
            render={props => (
              <NumberInput min={0} max={99} placeholder={0} {...props}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            )}
          />
        </GridItem>

        <GridItem colSpan={4}>
          <FormItem
            name="guestMessage"
            label={formatMessage(messages.inputGuestMessageLabel)}
            rules={{
              required: isMessageRequired
            }}
            render={props => (
              <Textarea
                placeholder={formatMessage(messages.inputGuestMessagePlaceholder)}
                {...props}
              />
            )}
          />
        </GridItem>

      </Grid>


    </Box>
  );

};


export default EnquiryFormComponent;

import EnquiryForm from '@rentivo/gatsby-core/src/components/forms/EnquiryForm/component';
import React from 'react';

EnquiryForm.defaultProps = {
  loading: false,
  areGuestsRequired: true,
  isMessageRequired: false,
  defaultValues: {}
};

export default EnquiryForm;

import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PropertyEnquiry/components/PropertyEnquiryForm/components/AvailabilityPickerPopover/messages';
import PopoverButton from '@rentivo/gatsby-core/src/components/ui/PopoverButton';
import loadable from '@loadable/component';
import { Box, Text } from '@chakra-ui/react';
const Component = loadable(() => import('@rentivo/gatsby-core/src/containers/PricingProvider/components/PropertyEnquiry/components/PropertyEnquiryForm/components/AvailabilityPicker'));
const dateFormat = {year: 'numeric', month: 'short', day: '2-digit'};

const AvailabilityPickerPopoverComponent = ({className, component, componentProps, children, styleProps = {}}) => {
  const { formatDate } = useIntl();
  let { startDate, endDate } = componentProps;
  const areDatesSelected = (startDate && endDate);
  const [visible, setVisible] = useState(false);

  startDate = (startDate) ? formatDate(startDate, dateFormat) : null;
  endDate = (endDate) ? formatDate(endDate, dateFormat) : null;

  return (
    <Box mb={3} className={className} {...styleProps}>
      <Text as="label" display="block" fontWeight="medium" mb={2}><FormattedMessage {...messages.title}/></Text>
      <PopoverButton
        component={component ? component : Component}
        componentProps={componentProps}
        visible={visible}
        popoverWidth={319}
        popoverHeight={339}
        onVisibleChange={() => setVisible(!visible)}
        buttonLabel={areDatesSelected ? <FormattedMessage {...messages.setLabel} values={{startDate, endDate}}/> : <FormattedMessage {...messages.label}/>}
      />
    </Box>
  );
};

export default AvailabilityPickerPopoverComponent;

import { defineMessages } from 'react-intl';

export const scope = 'app.components.forms.EnquiryForm';

export default defineMessages({
  inputDatesErrorRequired: {
    id: `${scope}.input.dates.error.required`,
    defaultMessage: 'Check-in & check-out is required',
  },
  inputFirstNameLabel: {
    id: `${scope}.input.firstName.label`,
    defaultMessage: 'First Name',
  },
  inputFirstNameErrorRequired: {
    id: `${scope}.input.firstName.error.required`,
    defaultMessage: 'Please enter your first name',
  },
  inputLastNameLabel: {
    id: `${scope}.input.lastName.label`,
    defaultMessage: 'Last Name',
  },
  inputLastNameErrorRequired: {
    id: `${scope}.input.lastName.error.required`,
    defaultMessage: 'Please enter your last name',
  },
  inputEmailLabel: {
    id: `${scope}.input.email.label`,
    defaultMessage: 'Email',
  },
  inputEmailErrorRequired: {
    id: `${scope}.input.email.error.required`,
    defaultMessage: 'Please enter your email',
  },
  inputEmailErrorValid: {
    id: `${scope}.input.email.error.valid`,
    defaultMessage: 'Please enter a valid email',
  },
  inputPhoneLabel: {
    id: `${scope}.input.phone.label`,
    defaultMessage: 'Phone',
  },
  inputPhoneErrorRequired: {
    id: `${scope}.input.phone.error.required`,
    defaultMessage: 'Please enter your phone number (including extension)',
  },
  inputGuestMessageLabel: {
    id: `${scope}.input.guestMessage.label`,
    defaultMessage: 'Message',
  },
  inputGuestMessagePlaceholder: {
    id: `${scope}.input.guestMessage.placeholder`,
    defaultMessage: 'What would you like to ask?',
  },
  inputGuestMessageErrorRequired: {
    id: `${scope}.input.guestMessage.error.required`,
    defaultMessage: 'Please enter a message to the manager',
  },
  inputGuestAdultCountLabel: {
    id: `${scope}.input.guestAdultCount.label`,
    defaultMessage: 'Adults',
  },
  inputGuestAdultCountRequired: {
    id: `${scope}.input.guestAdultCount.error.required`,
    defaultMessage: 'Required',
  },
  inputGuestChildrenCountLabel: {
    id: `${scope}.input.guestChildrenCount.label`,
    defaultMessage: 'Children',
  },
  inputGuestChildrenCountRequired: {
    id: `${scope}.input.guestChildrenCount.error.required`,
    defaultMessage: 'Required',
  },
  inputGuestInfantCountLabel: {
    id: `${scope}.input.guestInfantCount.label`,
    defaultMessage: 'Infants',
  },
  inputGuestInfantCountRequired: {
    id: `${scope}.input.guestInfantCount.error.required`,
    defaultMessage: 'Required',
  },
  inputPetsCountLabel: {
    id: `${scope}.input.petsCount.label`,
    defaultMessage: 'Pets',
  },
  inputPetsCountRequired: {
    id: `${scope}.input.petsCount.error.required`,
    defaultMessage: 'Required',
  },
  enquiryPricePeriod: {
    id: `${scope}.enquiryPricePeriod`,
    defaultMessage: 'For your dates',
  },
  enquirySuccessTitle: {
    id: `${scope}.enquirySuccessTitle`,
    defaultMessage: 'Your message has been sent',
  },
  enquirySuccessMessage: {
    id: `${scope}.enquirySuccessMessage`,
    defaultMessage: 'Thanks for your enquiry. Please make sure to check both inbox and junk mail for a response.',
  },
  enquiryErrorTitle: {
    id: `${scope}.enquiryErrorTitle`,
    defaultMessage: 'Woops, an error has occurred',
  },
  enquiryErrorMessage: {
    id: `${scope}.enquiryErrorMessage`,
    defaultMessage: 'Our team is on it. Please try again later.',
  },
  enquiryDatesLabel: {
    id: `${scope}.enquiryDatesLabel`,
    defaultMessage: 'Check-in & Check-out',
  },
  enquiryButtonText: {
    id: `${scope}.enquiryButtonText`,
    defaultMessage: 'Send Enquiry',
  },
  clearFormLabel: {
    id: `${scope}.clearFormLabel`,
    defaultMessage: 'Clear form',
  },
});

import React from 'react';
import PropTypes from 'prop-types';
import AvailabilityPickerPopover from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PropertyEnquiry/components/PropertyEnquiryForm/components/AvailabilityPickerPopover/component';

AvailabilityPickerPopover.defaultProps = {
  id: null,
  title: null,
  className: 'pricing-popover',
  component: null,
  componentProps: {
    defaultValues: null,
    startDate: null,
    endDate: null
  }
};

AvailabilityPickerPopover.propTypes = {
  id: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  className: PropTypes.string,
  component: PropTypes.element,
  componentProps: PropTypes.object
};

export default AvailabilityPickerPopover;
